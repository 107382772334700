<template>
  <b-container>
    <b-row class="justify-content-md-center mt-5">
      <b-col md="6" class="body">
        <div class="mt-5">
              <h3 class="text-primary ">Ecosystem Access</h3>
              <h6 class="mb-4 text-muted">Please insert your account details below</h6>
              <b-row class="justify-content-md-center mt-2">
                <b-col md="8">
                  <b-form @submit.prevent="login">
                    <b-form-group id="input-group-1" label="Email" label-for="input-1" description="">
                      <b-form-input id="email" v-model="form.email" type="email" required placeholder=""></b-form-input>
                    </b-form-group>

                    <b-form-group id="input-group-password" label="Password" label-for="input-1" description="">
                      <b-form-input id="password" v-model="form.password" type="password" required></b-form-input>
                      <small ><a href="#" class="text-dark" v-b-modal.forgottenPassword>Forgotten Password?</a></small>
                    </b-form-group>

                    <b-modal cancel-title="Cancel" cancel-variant="outline" ok-title="Send" @ok="forgottenPassword(emailForgottenPassword)" id="forgottenPassword" title="Password Reset">
                      <b-row class="justify-content-md-center">
                        <b-col md="10">
                          <b-form-group id="forgotPasswordForm" label label-for="input-1" description="We will send you an email to reset your access credentials">
                            <b-form-input id="forgottenPasswordForm" v-model="emailForgottenPassword" type="email" required placeholder="Insert Your Email Address"></b-form-input>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-modal>
                    <b-button class="mt-4" block type="submit" variant="primary">Sign In</b-button>
                    <p v-if="feedback" class="text-danger text-weight-bold mt-3">{{ feedback }}</p>
                  </b-form>

                  <p class="mt-3 mb-4">
                    <router-link class="" to="/signup">I don't have an account</router-link>
                    <br/>
                    <small>For any issue, email us at   <a href = "mailto: ecosystem@mindthebridge.org">ecosystem@mindthebridge.org</a></small>
                    </p>
                </b-col>
              </b-row>
            </div>
      </b-col>
    </b-row>
    
  </b-container>
</template>

<script>
import firebase from "firebase/app";
require("firebase/firestore");
require("firebase/analytics");


export default {
  name: "Login",
  data() {
    return {
      feedback: null,
      form: {
        email: "",
        password: "",
      },
      emailForgottenPassword: null,
    };
  },
  created() {
    if (firebase.auth().currentUser) {
        this.$router.push({ path: "/reports" });
    

      // if (!this.$store.state.userInfo) {
      //   this.$store.dispatch("setUserInfo").then(() => {
      //     if (this.$store.state.userInfo.hasCompletedSignup) {
      //       this.$router.push({ path: "/reports" });
      //     } else {
      //       this.$router.push({ path: "/complete-signup" });
      //     }
      //   })
      // } else {
      //     if (this.$store.state.userInfo.hasCompletedSignup) {
      //       this.$router.push({ path: "/reports" });
      //     } else {
      //       this.$router.push({ path: "/complete-signup" });
      //     }
      // }
    }
  },
  methods: {
    emailVerifiedToast() {
      this.toastCount++;
      this.$bvToast.toast("Your email has been successfully verified. You can now log in!", {
        title: "Email Verified",
        variant: "success",
        autoHideDelay: 5000,
        appendToast: false,
      });
    },
    passwordResetToast() {
      this.toastCount++;
      this.$bvToast.toast("We sent you an email to reset your password", {
        title: "Password Reset",
        variant: "success",
        autoHideDelay: 5000,
        appendToast: false,
      });
    },
    forgottenPassword(emailAddress) { 
      let auth = firebase.auth();
      var actionCodeSettings = {
        url: "https://research.mindthebridge.com/login",
      };
      auth.sendPasswordResetEmail(emailAddress,actionCodeSettings).then(() => {
        this.passwordResetToast();
      });
    },
    login() {
      if (this.form.email && this.form.password) {
        this.feedback = null;
        firebase
          .auth()
          .signInWithEmailAndPassword(this.form.email, this.form.password)
          .then((userCredential) => {

            firebase.analytics().logEvent('login').then(()=>{console.log('login event logged')})
            
            //CHANGE THIS TO RECORD EVERY ACCESS ON FIRESTORE
            // sf_challenge_apps.set({ accesses: admin.firestore.FieldValue.arrayUnion(admin.firestore.Timestamp.now()) }, { merge: true });

            firebase
              .firestore()
              .collection("users")
              .doc(userCredential.user.email)
              .get()
              .then((doc) => {
                var userInfo = doc.data();
                this.$store.commit("SET_USER_INFO", userInfo);
                if (!userInfo.hasCompletedSignup) {
                  this.$router.push({ name: "CompleteSignup" });
                } else {
                  this.$router.push({ name: "Reports" });
                }
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            this.feedback = err.code;
            switch (err.code) {
              case "auth/wrong-password":
                this.feedback = "The provided password is wrong.";
                break;

              case "auth/user-not-found":
                this.feedback = "The provided email is not associated to any user.";
                break;

              case "auth/too-many-requests":
                this.feedback = "Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.";
                break;

              default:
                this.feedback = null;
                break;
            }
          });
      } else {
        this.feedback = "Please fill in both fields";
      }
    },
  },
};
</script>


<style scoped>
.body{
  backdrop-filter: blur(4px);
  background-color: rgba(255, 255, 255, .70);  
  border-radius: 25px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; 
}
</style>